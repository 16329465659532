/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import getThemeConfig from '../providers/config/themeConfig'
import { useIdentity } from '../providers/identityContext'
import handlePageContext from '../utils/handlePageContext'
import Layout from '../Layout'
import MicroFrontend from '../components/MicroFrontend'
// This Account Page is used for the new onboarding route created by Joe Baker "/account/profile"
function Account({ data, location, pageContext }) {
  const { user, loggedIn, login, logout } = useIdentity()

  const {
    footer: { type },
  } = getThemeConfig()

  const { footer, footerNav, header, headerAuth } = data

  const headerProps = loggedIn && headerAuth ? headerAuth : header
  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: headerProps,
    footer: footerProps,
  }
  let microFrontendProps = {
    ...data.contentfulMicroFrontend,
    config: {
      actions: { login, logout },
      isNewOnboarding: true,
      basePath: '/account',
      location,
      user: {
        loggedIn,
        ...user,
      },
    },
  }

  // Handle if there are .env front end props
  microFrontendProps = handlePageContext(microFrontendProps, pageContext)

  return (
    <Layout {...layoutProps}>
      <MicroFrontend {...microFrontendProps} />
    </Layout>
  )
}

export const accountQuery = graphql`
  query AccountPage($mfInstanceName: String) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footer: contentfulFooter(instanceName: { eq: "Main Footer" }) {
      ...footerQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    contentfulMicroFrontend(instanceName: { eq: $mfInstanceName }) {
      instanceName
      name
      jsSrc
      cssSrc
    }
  }
`

Account.propTypes = {
  data: PropTypes.shape({
    contentfulHeader: PropTypes.object,
    contentfulFooter: PropTypes.object,
    contentfulMicroFrontend: PropTypes.object,
    footer: PropTypes.object,
    footerNav: PropTypes.object,
    header: PropTypes.object,
    headerAuth: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    jsSrc: PropTypes.string,
    cssSrc: PropTypes.string,
  }).isRequired,
}

export default Account
